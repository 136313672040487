@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

$darkColor: #2b3034;

:root {
    --bs-border-radius: 25px;
    --bs-border-color: #d3d3d3;
}

//Add box shadow
@mixin add-box-shadow($color: $darkColor, $a: 5px, $b: 5px, $c: 0px) {
    box-shadow: $a $b $c $color;
}

#root {
    padding: 30px 0 130px;

    @include media-breakpoint-up(md) {
        padding: 50px 0 20px;
    }

    background-color: #ffe9dc;
    background-image: linear-gradient(#dacac0 0.1em, transparent 0.1em),
        linear-gradient(90deg, #dacac0 0.1em, transparent 0.1em);
    background-size: 5em 5em;
    background-position: top center;
}

h1 {
    font-weight: 700;
    margin-bottom: 25px;
    display: table;
    margin: 0 auto 30px;
    padding: 10px 20px;
    background: white;
    border-radius: 25px;
    border: 2px solid $darkColor;
    @include add-box-shadow();

    @include media-breakpoint-up(md) {
        padding: 15px 25px;
        margin-bottom: 40px;
    }
}

a {
    font-weight: 600;
    color: #1b3755;
}

.container {
    max-width: 1100px;
}

.box {
    h4 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    padding: 15px;
    border: 2px solid #a39e9e;
    border-radius: 10px;
    background: #feffef;
}

.col-form-label {
    font-weight: 600;
}

.form-check-label,
input[type="checkbox"],
input[type="radio"],
input[type="color"] {
    cursor: pointer;
}

.qr-preview {
    @include media-breakpoint-down(md) {
        align-items: flex-start;
        background: #fff;
        display: flex;
        gap: 15px;
        height: 130px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        padding-left: calc(var(--bs-gutter-x) * 0.65);
        padding-right: calc(var(--bs-gutter-x) * 0.65);
        padding-top: 8px;
        padding-bottom: 12px;
        position: fixed;
        z-index: 2;
        border: 3px solid $darkColor;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        .content {
            .text-danger {
                text-transform: capitalize;
                padding: 5px;
                font-size: 12px;
                width: 110px;
                height: 110px;
                display: flex;
                align-items: center;
                text-align: center;
            }
            canvas {
                width: 110px;
            }
        }

        .btn {
            font-size: 14px;
            width: 100%;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0;
        }
    }

    @include media-breakpoint-up(md) {
        position: sticky;
        top: 20px;

        padding: 20px;
        background: #fff;
        @include add-box-shadow();
        border: 2px solid $darkColor;

        border-radius: var(--bs-border-radius);

        .content {
            .text-danger {
                padding: 15px;
            }
        }

        .input-group {
            .form-select,
            .btn {
                font-size: 16px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .input-group {
            flex: 1;
            padding: 0 !important;
            align-items: center;
            padding-top: 5px !important;
            .input-group-text {
                border: none;
                background: none;
                padding-right: 10px;
                font-size: 14px;
                margin-bottom: 15px;
            }
            .form-select,
            .btn {
                font-size: 14px;
                width: 100%;
                border-radius: 25px !important;
            }
            .form-select {
                text-align: center !important;
                flex: 1;
                margin-bottom: 15px;
            }
        }
    }

    .content {
        .text-danger {
            background-color: #fff9e5;
            border: 1.5px solid rgb(220, 53, 69);
            border-radius: var(--bs-border-radius);
        }

        canvas {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.accordion {
    --bs-primary-bg-subtle: #fbc82e;
    --bs-accordion-border-width: 2px;
    --bs-accordion-border-color: #2b3034;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-active-color: #2b3034;

    .accordion-item {
        .accordion-header {
            .accordion-button {
                font-weight: 700;
                &.hide-icon {
                    &:after {
                        display: none;
                    }
                }
                &.collapsed {
                    background: #feffef;
                }
            }
        }
    }

    @include add-box-shadow();
    border-radius: var(--bs-border-radius);
}

.form-control,
.form-select,
.form-check-input {
    &:focus {
        border-color: #ddb12b;
        outline: 0;
        box-shadow: 0 0 0 0.15rem rgb(251 200 46 / 32%);
    }
}
.form-check-input:checked {
    background-color: #deac13;
    border-color: #deac13;
}

.form-control-color {
    padding: 0;
    border: 2px solid $darkColor;
    border-radius: 25px;
}

.btn-cancel {
    @include media-breakpoint-down(md) {
        padding: 4px 0;
    }
}
